import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { getSingleOrganizationApi } from '../../api/organizationApi';
import { LinkTile } from "../../shared/linkTile";
import { Organization } from "../../api/models/organization";
import { useParams, useNavigate } from 'react-router-dom';
import { ErrorHeader } from "../../shared/errorHeader";
import { getAccess } from "../../api/userApi";
import { OrgHeader } from "../../shared/orgHeader";
import { BasicBackButton } from "../../shared/basicBackButton";
import { LoginHeader } from "../../shared/loginHeader";

export function OrganizationPage() {

    const [organization, setOrganization] = useState<Organization | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const params = useParams();
    const navigate = useNavigate();

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {

        const initPage = async () => {

            try {
                setIsLoading(true);
                setErrorMessage('');

                if (!params.orgId) {
                    throw Error('Organization Id is missing');
                }

                let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });

                const access = await getAccess(accessToken, params.orgId, "org");
                setIsAdmin(access === "admin" || access === "superadmin");

                if (!access) {
                    throw Error('You dont have permission to access this data');
                }

                const organization = await getSingleOrganizationApi(accessToken, params.orgId);
                setOrganization(organization);
                setIsLoading(false);

            } catch (error: any) {
                if (error.response?.data) {
                    setErrorMessage(error.response.data.message);
                }
                else {
                    setErrorMessage(error.message);
                }
                setIsLoading(false);
            }

        }
        initPage();

    }, [getAccessTokenSilently]);


    function adminPageHandler() {
        if (isAuthenticated && organization) {
            navigate(`/orgs/${organization.id}/admin`);
        }
    }

    function renderHeader() {
        return (
            <Grid container spacing={2} justifyContent="flex-start" alignItems="center" >
                <Grid item><h1>{organization!.name}</h1></Grid>
                {isAdmin && <Grid item> <Button color="primary"
                    variant="contained"
                    onClick={e => adminPageHandler()} >
                    Admin
                </Button></Grid>}
            </Grid>
        );
    }

    function renderSeasons() {
        return (
            <Grid container spacing={2}>
                {organization!.orgSeasons.map(item =>
                    <Grid item key={item.id}>
                        <LinkTile firstName={item.name}
                            key={item.id}
                            linkUrl={'/orgseason/' + item.id}
                            imageUrl={item.logoLink} />
                    </Grid>
                )}
            </Grid>
        );
    }

    function renderNoSeasons() {
        return (
            <Box>
                <p>No seasons have been created for this organization</p>
            </Box>
        );
    }

    function mainContent() {
        return (
            <Box>
                {renderHeader()}
                <h3>Seasons</h3>
                {organization!.orgSeasons && organization!.orgSeasons.length > 0 && renderSeasons()}
                {(!organization!.orgSeasons || organization!.orgSeasons.length === 0) && renderNoSeasons()}
            </Box>
        );
    }

    return (
        <Box>
            <LoginHeader />
            <ErrorHeader errorMessage={errorMessage} />
            <Grid container>
                <Grid item>{<BasicBackButton labelText='' navigateUrl={`/orgs`} />}</Grid>
            </Grid>

            {isLoading && <CircularProgress />}
            {organization && mainContent()}
        </Box>
    );

}