import { Box,  Button,  Grid, TextField} from '@mui/material';
import React from 'react';
import { Member } from '../../api/models/member';
import { BasicCheckBox } from '../../shared/basicCheckBox';

export interface MemberTabProps {
    member: Member,
    isLoading: boolean,
    onSubmit: (athletePage: string, isPublic:boolean) => Promise<void>
}

export default function MemberPersonalPageTab(props: MemberTabProps) {

    const [athletePage, setAthletePage] = React.useState<string>(props.member.athletePage ?? '');
    const [isPublic, setIsPublic] = React.useState<boolean>(props.member.isPublic ?? false);

   function handleAthletePageChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target;
        setAthletePage(value);
    }

    function submitForm() {
        props.onSubmit(athletePage, isPublic);
    }

    function setDefault() {
        setAthletePage(`http://www.colbrensports.com/orgSeason/${props.member.orgSeason.id}/athlete/${props.member.id}`);
    }

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Grid item>
                <Box mb={2}>
                   <BasicCheckBox label='Public' checked={isPublic} onChange={(e, checked) => setIsPublic(checked)} />
                   </Box>
                   <br/>
                </Grid>

                <Grid item>
                <Box mb={2}>
                {isPublic && athletePage && (
                       <Box>
                           <a href={athletePage} target="_blank" rel="noopener noreferrer">
                               Visit Athlete Page
                           </a>
                       </Box>
                   )}
                   </Box>
                   <br/>
                </Grid>

                <Grid item>
                <Box mb={2}>
                    <TextField id="athletePage"
                        label="Personal Page"
                        variant="outlined"
                        size="small"
                        helperText='50 character limit'
                        inputProps={{ maxLength: 50 }}
                        value={athletePage}
                        style={{ width: 1200 }}
                        onChange={handleAthletePageChange} />

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={setDefault}
                    >
                        Set Default
                    </Button>

                        <br/>
                </Box>
                </Grid>
                <Grid item>
                    <Box mb={2}>
                    <br/>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={submitForm}
                    >
                        Submit
                    </Button>
                    </Box>
                    </Grid>
            </Grid>
        </Grid>
    )
}