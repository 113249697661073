import QRCode from 'qrcode';

export async function generateQRCode(url:string):Promise<string>{
    var opts = {
        scale:1,
        width:21
     };

   const qrCode = await QRCode.toDataURL(url, opts);
   return qrCode;
}
