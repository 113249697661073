import { AppBar, Toolbar, Box, Grid } from '@mui/material';
import { AuthenticationButton } from '../auth/authenticationButton';


export interface HeaderProps {
    hideAuth?: boolean
}

export function Header(props: HeaderProps) {

    return (
        <Box>
            <AppBar position='sticky'>
                <Toolbar>
                    <Grid container spacing={2} alignItems="center" justifyContent="center" >
                        <Grid item xs={8} >
                            <Box component='img' src='/images/headerLogo.png' alt='' />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    );
}