import React, { useState, useEffect } from "react";

import { Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { styled } from '@mui/material/styles';

import { IconButton } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#003974',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


export interface RowItem {
  id: string,
  items: TableItem[]
}

export interface TableItem {
  name: string,
  value: string
}

interface MiniGridInputProps {
  rows: RowItem[],
  width?: number,
  onDelete?: (id: string) => Promise<boolean>,
  onEdit?: (id: string) => Promise<boolean>
}

export function MiniGrid(props: MiniGridInputProps) {

  const [tableWidth, setTableWidth] = useState<number>(props.width ?? 800);

  function deleteRecord(id: string) {
    if (props.onDelete) {
      const isSuccess = props.onDelete(id);
    }
  }

  function editRecord(id: string) {
    if (props.onEdit) {
      const isSuccess = props.onEdit(id);
    }
  }

  function resolveItem(textData: string) {

    const isLink = textData.startsWith('https');

    return (<>
      {!isLink && <span>{textData}</span>}
      {isLink && <img src={textData} height={40} alt='logo' />}
    </>);
  }


  return (
    <TableContainer component={Paper} sx={{ width: tableWidth }}>
      <Table sx={{ width: tableWidth }} aria-label="simple table" size='small'>
        <TableHead>
          <TableRow>
            {props.rows[0].items.map((item) => (
              <StyledTableCell key={item.name} align="left">{item.name}</StyledTableCell>
            ))}

            {props.onEdit && <StyledTableCell align="left" width={40}>&nbsp;</StyledTableCell>}

            {props.onDelete && <StyledTableCell align="left" width={40}>&nbsp;</StyledTableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows && props.rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

              {row.items.map((item, index) => (
                <StyledTableCell key={`${row.id}_${index}`} align="left">{resolveItem(item.value)}</StyledTableCell>
              ))}

              {props.onEdit && <StyledTableCell align="left">
                <IconButton aria-label="delete" onClick={(e) => editRecord(row.id)} >
                  <EditIcon />
                </IconButton>
              </StyledTableCell>}

              {props.onDelete && <StyledTableCell align="left">
                <IconButton aria-label="delete" onClick={(e) => deleteRecord(row.id)} >
                  <ClearIcon />
                </IconButton>
              </StyledTableCell>}
            </TableRow>
          ))}
        </TableBody>

      </Table>
    </TableContainer>
  );

};
