import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, CircularProgress, Grid } from '@mui/material';
import { getSingleOrganizationApi } from '../../api/organizationApi';
import { useParams } from 'react-router-dom';
import { ErrorHeader } from "../../shared/errorHeader";
import { getAccess } from "../../api/userApi";
import { OrgHeader } from "../../shared/orgHeader";
import { ManageLogoPanel } from "./manageLogoPanel";
import { ManageOrgSeasonsPanel } from "./manageOrgSeasonsPanel";
import { ManageUsersPanel } from "./manageUsersPanel";
import { Organization } from "../../api/models/organization";
import { BasicBackButton } from "../../shared/basicBackButton";
import { LoginHeader } from "../../shared/loginHeader";

export const OrganizationAdminPage: React.FC = (): JSX.Element => {

    const [organization, setOrganization] = useState<Organization | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const params = useParams();
    const { user, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        updatePageData();
    }, []);

    async function updatePageData() {
        try {
            setIsLoading(true);
            setErrorMessage('');

            if (!params.orgId) {
                throw Error('Organization Id is missing');
            }

            const accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });
            const access = await getAccess(accessToken, params.orgId, "org");
            const isAdmin = access === "admin" || access === "superadmin";

            if (!isAdmin) {
                throw Error('You dont have permission to access this data');
            }

            const organization = await getSingleOrganizationApi(accessToken, params.orgId);
            setOrganization(organization);

            setIsLoading(false);
        }
        catch (error: any) {
            if (error.response?.data) {
                setErrorMessage(error.response.data.message);
            }
            else {
                setErrorMessage(error.message);
            }
            setIsLoading(false);
        }
    }

    const mainContent = () => {

        return (
            <Box sx={{ width: 600 }}>
                <Grid item container spacing={1} direction='column' justifyContent="flex-start">
                    <Grid item>
                        <Grid item container spacing={2} justifyContent="flex-start" alignItems="center" >
                            <Grid item><h1>{organization!.name} Admin Page</h1></Grid>
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2} direction='column'>

                        <Grid item>
                            <ManageLogoPanel organizationId={organization!.id} />
                        </Grid>

                        <Grid item>
                            <ManageOrgSeasonsPanel organizationId={organization!.id} />
                        </Grid>

                        <Grid item>
                            <ManageUsersPanel organizationId={organization!.id} />
                        </Grid>

                    </Grid>

                </Grid>
            </Box>
        );
    }

    return (<Box>
        <LoginHeader />
        <ErrorHeader errorMessage={errorMessage} />
        <Box>{organization && <BasicBackButton labelText='' navigateUrl={`/orgs/${organization.id}`} />}</Box>
        {isLoading && <CircularProgress />}
        {organization && mainContent()}
    </Box>
    );
}