import { useEffect, useState } from "react";
import { Box,CircularProgress } from '@mui/material';
import { ErrorHeader } from "../../shared/errorHeader";

import { useParams } from "react-router-dom";
import { getRunnerApi} from "../../api/runnerApi";

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { MiniGrid, RowItem } from "../../shared/miniGrid";
import { formatHeight } from "../../utils/dataFormatters";
import { RunnerInfo, RunnerRaces, RunnerTraining } from "../../api/models/runner";

dayjs.extend(duration);


export function RunnerPage() {

    const [runner, setRunner] = useState<RunnerInfo | undefined>(undefined);
    const [raceRows, setRaceRows] = useState<RowItem[] | undefined>(undefined);
    const [trainingRows, setTrainingRows] = useState<RowItem[] | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const params = useParams();

    const birthDate = dayjs(runner?.birthDate);
    const age = dayjs().diff(birthDate, 'year');

    useEffect(() => {
        initPage();
    }, []);

    const initPage = async () => {
        try {

            setIsLoading(true);
            setErrorMessage('');

            if (!params.runnerGuid) {
                throw Error('Runner GUID is missing');
            }

            await refreshMember(params.runnerGuid);
            setIsLoading(false);
        } 
        catch (error: any) {
            if (error.response?.data) {
                setErrorMessage(error.response.data.message);
            }
            else {
                setErrorMessage(error.message);
            }
            setIsLoading(false);
        }
    }

    function mapRacesToRows(responseRaces?: RunnerRaces[]) {
        if (responseRaces && responseRaces.length > 0) {
            const tempRows = responseRaces.map((rowItem) => ({
                id: rowItem.startDate.toString(),
                items: [{ name: 'date', value:dayjs(rowItem.startDate).format('MM/YY') }, 
                        { name: 'org', value: rowItem.raceName},
                        
                    ]
            }));

            setRaceRows(tempRows);
        }
    }

    
    function mapTrainingToRows(responseTraining?: RunnerTraining[]) {
        if (responseTraining && responseTraining.length > 0) {
            const tempRows = responseTraining.map((rowItem) => ({
                id: rowItem.startDate.toString(),
                items: [{ name: 'date', value:dayjs(rowItem.startDate).format('MM/YY') }, 
                        { name: 'org', value: rowItem.name},
                        
                    ]
            }));

            setTrainingRows(tempRows);
        }
    }


    async function refreshMember(runnerGuid: string) {
        const response = await getRunnerApi(runnerGuid);
        setRunner(response);
        if (response.races) {
            mapRacesToRows(response.races);
        }
        if (response.training) {
            mapTrainingToRows(response.training);
        }
    }

    const mainContent = () => {

        return (
            
                <Box>
                    <br/>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={runner?.orgSeason?.logoLink} height={50} alt='profile' />
                        {runner?.orgSeason && <h2 style={{ margin: '0 10px' }}>{runner?.orgSeason.name}</h2>}
                    </div>

                    <h1>{runner?.firstName}  {runner?.lastName} </h1>
                    {<img src={runner?.profileLink} height={200} alt='profile' />}
                    {runner?.team && <h3 style={{ margin: '0 10px' }}>{runner?.team.name} </h3>}
                    

                    <br />
                    
                    <h2>Bio</h2>
                    <table>
      <tbody>
        <tr>
          <td>Height:</td>
          <td><strong>{formatHeight(runner?.height)}</strong></td>
        </tr>
        <tr>
          <td>Weight:</td>
          <td><strong>{runner?.weight}</strong></td>
        </tr>
        <tr>
          <td>Birth Date:</td>
          <td><strong>{birthDate.format('MM/DD/YY')}</strong></td>
        </tr>
        <tr>
          <td>Age:</td>
          <td><strong>{age}</strong></td>
        </tr>
      </tbody>
    </table>

                    <h2>Races</h2>
                    {raceRows && <MiniGrid rows={raceRows} width={500} />}

                    <br/><br/>
                    <h2>Training</h2>
                    {raceRows && <MiniGrid rows={raceRows} width={500} />}

                    <br/><br/>
                    {runner?.qrCodeLink && <img src={runner?.qrCodeLink} height={200} alt='profile' />} 
                   

                    <br />

                </Box>
        );
    }

    return (<Box>
        <ErrorHeader errorMessage={errorMessage} />
        {isLoading && <CircularProgress />}
        {runner && mainContent()}
    </Box>
    );

}