import { AppBar, Toolbar, Box, Grid, Typography } from '@mui/material';
import { AuthenticationButton } from '../auth/authenticationButton';




export function LoginHeader() {

    return (
        <>
       
        <Box mt={1} mb={2}>
            <Grid container spacing={2} alignItems="left">
                <Grid item>
                    <AuthenticationButton />
                </Grid>
            </Grid>
        </Box>
    </>
    );
}