import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from '@mui/material';
import { getOrganizations } from '../../api/organizationApi';
import { LinkTile } from "../../shared/linkTile";
import { Organization } from "../../api/models/organization";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorHeader } from "../../shared/errorHeader";
import { useApi } from "../../hooks/useApi";
import { LoginHeader } from "../../shared/loginHeader";

export function OrganizationListPage() {
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getOrgsApi = useApi(getOrganizations);

    const { user,getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

    useEffect(() => {

        const initPage = async () => {

            try {
                setIsLoading(true);
                setErrorMessage('');
                let accessToken = '';
                try {
                    accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });
                }
                catch
                {
                    accessToken = await getAccessTokenWithPopup({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });
                }

                const orgs = await getOrganizations(accessToken);
                setOrganizations(orgs);
                setIsLoading(false);

            }
            catch (error: any) {
                if (error.response?.data) {
                    setErrorMessage(error.response.data.message);
                }
                else {
                    setErrorMessage(error.message);
                }
                setIsLoading(false);
            }
        }

        initPage();

    }, [getAccessTokenSilently, getAccessTokenWithPopup]);

    const mainContent = () => {

        return (
            <Box>
                <LoginHeader />
                <h1>Organizations</h1>
                <Grid container spacing={2}>
                    {organizations.map(item =>
                        <Grid item key={item.id}>
                            <LinkTile firstName={item.name}
                                key={item.id}
                                linkUrl={'/orgs/' + item.id}
                                imageUrl={item.logoLink} />
                        </Grid>
                    )}
                </Grid>
            </Box>
        );
    }

    return (

        <Box>
            <ErrorHeader errorMessage={errorMessage} />
            {isLoading && <CircularProgress />}
            {organizations && mainContent()}
        </Box>
    );

}